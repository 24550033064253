export const tiles = [
    [ 0,0,0,0,0,0,0,6,6,0,0,1,1,1,0,0,6,6,0,0,0,0,0,0,0,0,0,0,5,5,0,0,0,0,0,0,0,0,5,5 ],
    [ 0,0,0,0,0,0,0,6,6,0,0,1,1,1,0,0,6,6,0,0,0,0,0,0,0,0,0,0,5,5,0,0,0,0,0,0,0,0,5,5 ],
    [ 0,0,0,5,5,0,0,6,6,0,0,1,1,1,0,0,5,5,0,0,0,0,0,5,5,0,0,0,0,0,0,0,0,0,0,5,5,0,5,5 ],
    [ 0,0,0,5,5,0,0,6,6,0,0,0,0,0,0,0,5,5,0,0,0,0,0,5,5,0,0,0,0,0,0,0,0,0,0,5,5,0,5,5 ],
    [ 0,0,0,5,5,0,0,0,0,0,0,0,0,0,5,5,5,5,0,0,0,0,0,5,5,6,6,5,5,5,5,5,5,5,5,5,5,0,0,0 ],
    [ 0,0,0,5,5,0,0,0,0,0,0,0,0,0,5,5,5,5,0,0,0,0,0,5,5,6,6,5,5,5,5,5,5,5,5,5,5,0,0,0 ],
    [ 0,0,0,0,0,0,0,5,5,0,0,0,0,0,0,0,0,0,0,0,0,0,0,6,6,7,7,7,7,0,0,0,0,0,0,0,0,0,0,0 ],
    [ 0,0,0,0,0,0,0,5,5,0,0,0,0,0,0,0,0,0,0,0,0,0,0,6,6,7,7,7,7,0,0,0,0,0,0,0,0,0,0,0 ],
    [ 1,1,0,0,0,0,0,5,5,0,0,0,0,6,6,0,0,0,0,0,0,0,0,5,5,5,5,1,1,5,5,5,5,5,5,5,5,5,6,6 ],
    [ 1,1,0,0,0,0,0,5,5,0,0,0,0,6,6,0,0,0,0,0,0,0,0,5,5,5,5,1,1,5,5,5,5,5,5,5,5,5,6,6 ],
    [ 1,1,1,1,0,0,0,0,0,0,0,5,5,0,0,7,7,7,7,7,7,6,6,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0 ],
    [ 1,1,1,1,0,0,0,0,0,0,0,5,5,0,0,7,7,7,7,7,7,6,6,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0 ],
    [ 0,0,5,5,5,5,5,5,5,1,1,1,1,1,1,1,1,1,1,6,6,0,0,0,0,5,5,1,1,5,5,0,0,0,5,5,0,0,0,0 ],
    [ 0,0,5,5,5,5,5,5,5,1,1,1,1,1,1,1,1,1,1,6,6,0,0,0,0,5,5,1,1,5,5,0,0,0,5,5,0,0,0,0 ],
    [ 0,0,0,0,0,0,0,6,6,1,1,0,0,5,5,0,0,0,0,5,5,0,0,0,0,0,0,0,0,5,5,0,0,0,5,5,0,0,0,0 ],
    [ 0,0,0,0,0,0,0,6,6,1,1,0,0,5,5,0,0,0,0,5,5,0,0,0,0,0,0,0,0,5,5,0,0,0,5,5,0,0,0,0 ],
    [ 6,6,5,5,0,0,0,6,6,0,0,0,0,5,5,0,0,0,0,5,5,0,0,0,0,5,5,6,6,5,5,0,0,0,5,5,5,5,0,0 ],
    [ 6,6,5,5,0,0,0,6,6,0,0,0,0,5,5,0,0,0,0,5,5,0,0,0,0,5,5,6,6,5,5,0,0,0,5,5,5,5,0,0 ],
    [ 0,0,5,5,0,0,0,5,5,0,0,0,0,5,5,5,5,5,5,5,5,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0 ],
    [ 0,0,5,5,0,0,0,5,5,0,0,0,0,5,5,5,5,5,5,5,5,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0 ],
    [ 0,0,5,5,0,0,0,5,5,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,5,5,0,0,5,5,0,0,0,5,5,5,5,0,0 ],
    [ 6,6,5,5,0,0,0,0,0,0,0,0,0,0,0,5,5,5,5,0,0,0,0,0,0,5,5,0,0,5,5,0,0,0,5,5,5,5,0,0 ],
    [ 0,0,5,5,0,0,0,5,5,0,0,0,0,0,0,5,10.1,10.2,5,0,0,0,0,0,0,5,5,5,5,5,5,0,0,0,5,5,5,5,0,0 ],
    [ 0,0,5,5,0,0,0,5,5,0,0,0,0,0,0,5,10.3,10.4,5,0,0,0,0,0,0,5,5,5,5,5,5,0,0,0,5,5,5,5,0,0 ],
  ]